@import '../../assets/styles/imports/variable';
@import '../../assets/styles/imports/fonts';

.members-screen-wrap {
  margin: 36px 0 125px;
  .members-screen-view-row {
    .subtitle1 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    &:not(:last-child) {
    }
    &.no-border {
      border-bottom: 0;
    }
  }
  .members-screen-view-wrap {
    padding: 22px 30px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 0 16px 0 rgba($color: $black, $alpha: 0.08);
  }
}

.members-tabs-wrap {
  .nav-tabs {
    border-color: rgba($color: $primary-grey, $alpha: 0.2);
    .nav-link {
      padding: 14px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      color: $secondary-black;
      font-weight: 600;
      background: $white;
      border-color: rgba($color: $primary-grey, $alpha: 0.2);
      color: $primary-dark-color;
      font-size: 14px;
      &:hover {
        background: $secondary-white;
      }
      &:active {
        box-shadow: inset 0 3px 5px rgba($color: $black, $alpha: 0.13) !important;
      }
      &.active {
        background: $primary-color;
        color: $white;
      }
    }
  }
}

.members-table-striped {
  width: 100%;
  border-collapse: collapse;
  z-index: 1000;
  border: 1px solid #ededed;
  border-radius: 20px;

  th {
    font-weight: $font-weight-600;
    font-size: 14px;
    vertical-align: middle;
    background-color: #f0f0f0 !important;
    color: $secondary-black;
    padding: 8px 0;
    line-height: 22px;

    &:first-child {
      padding-left: 16px;
    }
  }

  td {
    padding: 8px 0;
    font-size: 14px;
    line-height: 1.2;
    vertical-align: middle;
    border-top: 1px solid rgba($color: $secondary-grey, $alpha: 0.25);
    background: $white;
    word-wrap: break-word;
    
    .members-approve-btn{
      border-radius: 15px;
      border: 1px solid lightgray;
      text-transform: none;
      padding: 5px 15px;

      &:hover{
        background-color: #333;
        color: white;
      }
    }

    &:first-child {
      font-weight: bold;
      cursor: pointer;
      padding-left: 16px;
    }
  }

  tbody tr {
    border-top: 1px solid rgba($color: $secondary-grey, $alpha: 0.25);
  }

  tbody {
    .disabled-row {
      td {
        background-color: #fbdede;
      }
    }
  }
}

.members-table-striped td:nth-child(7),
.members-table-striped th:nth-child(7) {
  max-width: 200px; /* Set a max-width for the rejection reason column */
  white-space: nowrap;
  // overflow: hidden;
  text-overflow: ellipsis;
}

.members-table-striped th,
.members-table-striped td {
  max-width: 150px; /* Set a max-width for all table cells */
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Add this CSS to make sure the rejection reason text wraps properly */
.rejection-reason {
  max-width: 200px; /* Adjust the max-width as needed */
}

.members-button-group {
  display: flex;
  gap: 0.5rem;

  .members-custom-btn {
    border-radius: 20px;
    padding: 15px;

    &:first-child {
      &:hover {
        background-color: $primary-color;
        color: $white;
      }
    }

    &:last-child {
      &:hover {
        color: $white;
      }
    }
  }
}

.members-three-dots {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba($primary-color, 0.1);
    cursor: pointer;
  }

  svg {
    font-size: 20px;
  }
}

.members-d-flex {
  display: flex;
}

.members-justify-content-between {
  justify-content: space-between;
}

.members-align-items-center {
  align-items: center;
}

.members-ml-2 {
  margin-left: 0.5rem;
}

.member-name-wrapper {
  display: flex;
  align-items: center;
  .initials {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-weight: bold;
  }

  .intitialNormal {
    color: #333;
  }

  .initialRed {
    border: 1px solid red;
    background-color: rgb(244, 209, 209);
    color: red;
  }

  span {
    text-decoration: underline;
    display: inline-block;
    max-width: 200px; /* Adjust max-width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.role-site {
  .site {
    color: gray;
    font-size: 12px;
  }
}

.specialty-location {
  .location {
    color: gray;
    font-size: 12px;
  }
  .more {
    color: #5d84e9;
    font-size: 13px;
    font-weight: bold;
  }
}

.time {
  color: gray;
  font-size: 12px;
}
