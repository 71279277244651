@import '../../assets/styles/imports/global';

.legend-wrap {
    @include flex(flex, space-between, center, nowrap);
    position: relative;
    &:before {
        content: "";
        width: 100%;
        @include transformY();
        height: 1px;
        background: rgba($color: $secondary-blue, $alpha: 0.2);
        z-index: 0;
    }
    .legend-title {
        background: $secondary-white;
        padding: 6px 12px;
        border-radius: 3px;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: $font-weight-700;
        color: $secondary-blue;
        position: relative;
        z-index: 1;
    }
    .legend-actions {
        position: absolute;
        z-index: 1;
        @include transformY;
        right: 0;
    }
}