
// @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

@mixin font-face($style-name, $file, $family) {
    $filepath: '../../fonts/' + $family + '/' + $file;
    @font-face {
      font-family: '#{$style-name}';
      src: url($filepath + '.eot');
      src: url($filepath + '.eot?#iefix') format('embedded-opentype'),
        url($filepath + '.woff') format('woff'),
        url($filepath + '.ttf') format('truetype'),
        url($filepath + '.svg#' + $style-name + '') format('svg');
    }
}
  
@include font-face('Lato-Thin', 'Lato-Thin', 'Lato-Thin');
@include font-face('Lato-Light', 'Lato-Light', 'Lato-Light');
@include font-face('Lato-Regular', 'Lato-Regular', 'Lato-Regular');
@include font-face('Lato-Medium', 'Lato-Medium', 'Lato-Medium');
@include font-face('Lato-Semibold', 'Lato-Semibold', 'Lato-Semibold');
@include font-face('Lato-Bold', 'Lato-Bold', 'Lato-Bold');


$font-thin: 'Lato-Thin'; //100
$font-light: 'Lato-Light'; //300
$font-reg: 'Lato-Regular'; //400
$font-med: 'Lato-Medium'; // 500
$font-semi: 'Lato-Semibold'; //600
$font-bold: 'Lato-Bold'; //700

$font-weight-100: 100;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;

  