.submitSuccess {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .grittyLogo {
    text-align: center;

    img {
      object-fit: contain;
      height: auto;
      width: 150px;
    }
  }

  .successBox {
    margin-top: 15px;
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(109, 109, 109, 0.75);
    border: none;
    outline: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;

    @media (max-width: 600px){
      width: 340px;
    }

    .corssIcon {
      display: flex;
      justify-content: flex-end;
      font-size: 20px;
      padding-top: 20px;
      padding-right: 20px;
      cursor: pointer;
    }

    .goBack {
      display: flex;
      justify-content: flex-start;
      font-size: 20px;
      padding-top: 20px;
      padding-left: 20px;

      .backIcon{
        // border: 1px solid gray;
        // border-radius: 5px;
        // padding: 10px;
        // color: black;
      }
      
    }

    .successInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin: 10px 0;

      .successEmail{
        font-weight: bold;
      }

      .underReview{
        border: 1px solid red;
        border-radius: 20px;
        background-color: transparent;
        display: flex;
        align-items: center;
        gap: 5px;
        color: red;
        padding: 8px;

        p{
          padding: 0;
          font-style: italic;
          font-weight: bold;
        }
      }

      h2 {
        font-size: 20px;
        font-weight: bolder;
      }

      .docLogo{
        background-color: rgba(154, 152, 152, 0.4);
        border: none;
        outline: none;
        border-radius: 50%;
        padding: 15px;

        img{
          object-fit: contain;
          height: auto;
          width: 50px;
        }
      }

      .docStoreLogo{
        img{
          object-fit: contain;
          height: auto;
          width: 80px;
        }
      }

      .tickLogo {
        img {
          object-fit: contain;
          height: auto;
          width: 100px;
        }
      }

      p {
        text-align: center;
        padding: 0 25px;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .submitSuccess {
    .successBox {
      padding: 10px;

      .corssIcon,
      .goBack {
        padding: 10px;
      }

      .successInfo {
        gap: 10px;
        margin: 5px 0;

        .tickLogo img,
        .docStoreLogo img,
        .docLogo img {
          width: 50px;
        }

        h2 {
          font-size: 18px;
        }

        p {
          font-size: 11px;
          padding: 0 10px;
        }
      }
    }
  }
}

