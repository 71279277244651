.invite-member-wrap {
    margin: 36px 0 125px;
  
    .invite-member-view-wrap {
      padding: 22px 30px 50px 30px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
      border: 1px solid gray;
      
      h1 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      
      .invite-url-box {
        display: flex;
        align-items: center;
        gap: 10px;
        
        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #D3D3D3;
          border-radius: 5px;
          color: #666;
          background-color: #f9f9f9;
        }
  
        .copy-link-btn {
          background-color: #414241;
          border: none;
          padding: 10px 20px;
          color: white;
          text-align: center;
          border-radius: 5px;
          text-transform: none;
          font-weight: normal;
          display: flex;
          align-items: center;
          gap: 2px;
        }
      }
    }
  }
  