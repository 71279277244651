
.search-box-wrapper {
  position: relative;
  width: 600px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 15px;

  .search-box {
    padding: 10px;
    font-size: 14px;
    border: none;
    outline: none;
  }

    .search-icon {
      font-weight: normal;
      font-size: 20px;
    }
}
