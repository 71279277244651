.toast-success {
    background-color: #4caf50 !important;
    width: 230px !important;
    margin: auto !important;

    .toast-message {
        color: #ffffff !important;
      }
  }

.toast-error {
    background-color: #BD362F !important;
    width: 230px !important;
    margin: auto !important;
}