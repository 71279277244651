.dropdownCheckbox {
  position: relative;
  width: 315px;

  @media (min-width: 650px){
     height: 40px;
     margin-bottom: 20px;
  }
  

  @media (max-height: 600px){
    max-height: fit-content;
    min-height: 40px;
  }

  .inputError {
    button {
      border: 1px solid red;
    }
  }

  .checkboxWrapper {
    display: flex;
    flex-direction: column;

    .error {
      color: red;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      font-weight: normal;
      margin-top: 4px;
    }
  }

  .selectedSummary {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;
    padding: 5px;

    .selectedOption {
      background-color: #e6e6e6;
      color: #333;
      border-radius: 5px;
      padding: 3px 7px;
      font-size: 0.8rem;
      display: flex;
      align-items: center;
    }

    .removeOption {
      display: inline-block;
      margin-left: 5px;
      cursor: pointer;
      color: #999;
    }
  }

  .dropdownButton {
    width: 100%;
    padding: 9px;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: #fff;
    color: #666;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;

    .dropdownIcon {
      font-size: 13px;
      color: #666; // Adjust color to match your design
    }
  }

  .dropdownContent {
    position: absolute;
    top: 100%; // Ensures it starts beneath the button
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
    border-radius: 4px;
    max-height: 150px; // Adjust as needed
    overflow-y: auto;

    .checkboxContainer {
      display: flex;
      gap: 7px;
      margin: 10px;
      cursor: pointer;
    }
  }
}
