// calendar start
.calendar-wrap {
  height: 700px;
  .rbc-toolbar {
      margin-bottom: 45px;
  }
  .rbc-toolbar-label {
      font-size: 20px;
      line-height: 1.3;
      letter-spacing: 0;
      font-weight: $font-weight-600;
      color: rgba($color: $secondary-blue, $alpha: 0.8);
  }
  .rbc-header {
      padding: 16px 8px;
      color: $secondary-blue;
      font-size: 16px;
  }
  .rbc-date-cell {
      text-align: left;
      padding: 16px 16px 8px;
      color: $secondary-blue;
      font-size: 14px;
      font-weight: $font-weight-600;
  }
  .rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
      font-size: 14px;
  }
  .rbc-day-slot {
      .rbc-event-content {
          font-size: 14px;
      }
      .rbc-background-event {
          background: $secondary-blue;
          &:hover {
              background: rgba($color: $secondary-blue, $alpha: 0.9);
          }
      }
  }
  .rbc-off-range-bg {
      background: rgba($color: $primary-grey, $alpha: 0.05);
      cursor: not-allowed;
  }
  .rbc-off-range {
      color: rgba($color: $primary-grey, $alpha: 0.6);
  }
  .rbc-event {
      background: $secondary-blue;
      border-color: $primary-blue;
  }
  .rbc-show-more {
      background: transparent;
      color: $secondary-blue;
  }
  .rbc-time-content {
      border-width: 1px;
  }
  .rbc-day-bg + .rbc-day-bg,
  .rbc-month-row + .rbc-month-row,
  .rbc-header + .rbc-header,
  .rbc-month-view,
  .rbc-header,
  .rbc-time-content,
  .rbc-time-view,
  .rbc-time-content > * + * > *,
  .rbc-timeslot-group,
  .rbc-time-header-content,
  .rbc-time-header.rbc-overflowing {
      border-color: rgba($color: $primary-grey, $alpha: 0.5);
  }
  .rbc-label {
      color: rgba($color: $secondary-blue, $alpha: 0.8);
      font-size: 16px;
      // font-weight: $font-weight-600;
  }
  .rbc-today {
      background: rgba($color: $shaded-green, $alpha: 0.1);
  }
  .rbc-toolbar {
      button {
          @include btn;
          // padding: 1px 8px;
          font-size: 14px;
          color: $primary-green;
          background: $primary-white;
          border-color: rgba($color: $primary-grey, $alpha: 0.2);
          &:hover {
              background: $secondary-white;
          }
          &:active {
              background: $primary-white;
              border-color: rgba($color: $primary-grey, $alpha: 0.4);
              color: $primary-green;
          }
          &.rbc-active {
              background-color: $primary-green;
              color: $white;
              &:hover {
                  color: $white;
              }
          }
      }
  }
}
// calendar end