//Media Query start
@mixin media-query-min($arg) {
    @media screen and (min-width: $arg) {
        @content;
    }
}
  
@mixin media-query-max($argM) {
    @media screen and (max-width: $argM) {
        @content;
    }
}
//Media Query end
  
// transform start
@mixin transformX {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
  
@mixin transformY {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
  
@mixin transformXY {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
// transform end


// fles start    
@mixin flex($flex, $justify-content, $align-items, $wrap) {
    display: $flex;
    justify-content: $justify-content;
    align-items: $align-items;
    flex-wrap: $wrap;
}
// fles end
  

// button start
@mixin btn {
    outline: none;
    padding: 8px 15px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: $font-reg;
    font-weight: $font-weight-700;
    cursor: pointer;
    box-shadow: none;
    white-space: nowrap;
    box-shadow: none !important;
    line-height: 20px;
    border-radius: 3px;
    &.active , &:active {
        box-shadow: inset 0 3px 5px rgba($color: $black, $alpha: 0.13) !important;
    }
} 
  
@mixin default-btn($background-color, $color, $radius) {
    @include btn;
    background: $background-color;
    color: $color;
    border-radius: $radius;
    box-shadow: none !important;
    height: 36px;
    &:focus {
        background: $background-color;
    }
    &:hover, &:active {
        background: rgba($color: $background-color, $alpha: 0.8);
    }
}
@mixin outline-btn($background-color, $color, $border-color, $radius) {
    @include btn;
    border: 1px solid $border-color !important;
    color: $color;
    border-radius: $radius;
    background: $background-color;
    &:hover {
      background: rgba($color: $border-color, $alpha: 0.08);
    }
}
@mixin contained-btn($background-color, $color, $border-color, $radius) {
    @include btn;
    border: 1px solid transparent;
    color: $color;
    border-radius: $radius;
    background: $background-color;
    box-shadow: 0px 16px 24px rgba($color: $background-color, $alpha: 0.3);
    &:hover {
      background: rgba($color: $background-color, $alpha: 0.9);
      box-shadow: 0px 16px 24px rgba($color: $background-color, $alpha: 0.1);
    }
}
@mixin circle-btn($background-color, $color) {
    @include btn;
    border: 1px solid transparent;
    color: $color;
    border-radius: 100%;
    background: $background-color;
    border: 1px solid $background-color;
    width: 56px;
    height: 56px;
    padding: 10px;
    min-width: 56px;
    &:hover {
      background: rgba($color: $background-color, $alpha: 0.8);
    }
    &.btn-small {
        width: 32px;
        height: 32px;
        min-width: 32px;
        .MuiSvgIcon-root {
            font-size: 34px;
        }
    }
    &.btn-medium {
        width: 40px;
        height: 40px;
        min-width: 40px;
        .MuiSvgIcon-root {
            font-size: 44px;
        }
    }
}
// button end
  
@mixin switch-color($thumb-color, $track-color) {
    .MuiSwitch-switchBase {
        &.Mui-checked {
            color: $thumb-color;
            &:hover {
                background-color: rgba($color: $track-color, $alpha: 0.04);
            }
            + .MuiSwitch-track {
                opacity: 1 !important;
                background-color: $track-color;
            }
        }
    }
}
  

@mixin inputFocus() {
    border-color: rgba($color: $primary-blue, $alpha: .4);
    outline: none;
    box-shadow: inset 0 0px 1px rgba($color: $primary-blue, $alpha: .2), 0 0 8px rgba($color: $primary-blue, $alpha: .6);
}