.survey-management {
  margin: 36px 0 125px;

  .content-wrap {
    padding: 22px 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid #ccc;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }

    .header-actions {
      display: flex;
      align-items: center;
      gap: 20px;

      .search-box-wrapper {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding-left: 10px;

        .search-icon {
          font-size: 20px;
        }

        .search-box {
          padding: 10px;
          border: none;
          outline: none;
        }
      }

      button {
        background-color: #414241;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 7px;
        font-size: 14px;
        font-weight: normal;
        text-transform: none;
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }
  }

  .nav-tabs {
    .nav-link {
      border: 1px solid #ddd;
      border-bottom: none;
      background-color: #f8f9fa;
      color: black;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-size: 16px;
      padding: 10px 20px;
      width: 129px;
      display: flex;
      justify-content: center;
      transition: background-color 0.3s ease, border-color 0.3s ease;

      &.disabled {
        color: #aaa !important;
        cursor: not-allowed;
        border: 1px solid #ddd !important;
        background-color: #f8f9fa !important;
      }

      &.active {
        background-color: #414241;
        color: #fff;
        border-color: #414241;
      }

      &:hover:not(.disabled) {
        border-color: #414241;
      }
    }
  }

  .table-wrapper {
    table {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #ccc;

      .surveyNameHeading {
        width: 13%;
      }

      .surveyGroupHeading {
        width: 18%;
      }

      th,
      td {
        padding: 15px;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }

      td {
        &:nth-child(2) {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        &:first-child {
          width: 20%; /* Adjust width as needed */
        }
      }

      .surveyName {
        text-transform: capitalize;
      }

      th {
        background-color: #f8f9fa;
      }

      .three-dots {
        position: relative;
        cursor: pointer;

        .options-card {
          position: absolute;
          top: 20px;
          right: 0;
          background: #fff;
          border: 1px solid #ddd;
          border-radius: 4px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          z-index: 10;
        }
      }
    }
  }
}

@mixin pageFont($color, $fontsize, $fontweight) {
  color: $color;
  /* Body/14/14-Regular */
  font-family: Lato;
  font-size: $fontsize;
  font-style: normal;
  font-weight: $fontweight;
}

.member-value {
  @include pageFont(blue, 16px, 600);
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  margin-right: 60px;

  .pagination-controls {
    display: flex;
    gap: 5px;

    button {
      background: none;
      border: 1px solid #ddd;
      padding: 5px 10px;
      cursor: pointer;
      border-radius: 6px;
      background-color: white;

      &:hover {
        background-color: #414241;
        color: white;
      }

      &.active {
        background-color: #414241;
        color: #fff;
      }
    }
  }
}
