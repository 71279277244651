.custom-switch {
    display: flex;
    align-items: center;
  
    .custom-switch-checkbox {
      display: none;
    }
  
    .custom-switch-label {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 20px;
      background-color: #ccc;
      border-radius: 20px;
      margin-right: 10px;
      cursor: pointer;
      transition: background-color 0.2s;
    }
  
    .custom-switch-label .custom-switch-inner {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #ccc;
      border-radius: 20px;
      transition: transform 0.2s, background-color 0.2s;
      transform: translateX(0);
    }
  
    .custom-switch-label .custom-switch-switch {
      position: absolute;
      width: 18px;
      height: 18px;
      background-color: #fff;
      border-radius: 50%;
      top: 1px;
      left: 2px;
      transition: transform 0.2s;
      color: #fff;
    }
  
    .custom-switch-checkbox:checked + .custom-switch-label .custom-switch-inner {
      background-color: #414241;
    }
  
    .custom-switch-checkbox:checked + .custom-switch-label .custom-switch-switch {
      transform: translateX(20px);
      color: #000;
    }
  
    .custom-switch-text {
      font-size: 14px;
      color: #000;
    }
  }
  