.custom-dropdown {
  position: relative;
  width: 315px;

  .dropdown-btn {
    width: 100%;
    padding: 9px;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: #fff;
    color: #666;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .dropdown-btn-title {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .custom-dropdown-icon {
      font-size: 13px;
      color: #666;
    }
  }

  .dropdown-content {
    position: absolute;
    top: 100%; // Adjusted to ensure it starts beneath the button
    width: 230px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
    border-radius: 4px;
    max-height: 200px; // Fixed height for the dropdown
    overflow-y: auto; // Scrollable content

    .dropdown-option {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      
      &:hover {
        background-color: #f5f5f5;
      }

      input {
        margin-right: 10px;
      }
    }
  }
}
