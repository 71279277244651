@import "../../assets/styles/imports/global";
@import "@y0c/react-datepicker/assets/styles/calendar.scss";

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  display: flex;
  align-items: center;
  gap: 3px !important;
}
.react-datepicker-popper {
  z-index: 100;
}
.form-date-wrap {
  .picker,
  .range-picker-input {
    display: block;
    width: 100%;
  }
  .picker-input {
    display: block;
    min-width: 100%;
  }
  .range-picker-input {
    display: flex;
  }
  &.is-invalid {
    .picker-input__text,
    .range-picker-input {
      border-color: $red;
      &:focus {
        box-shadow: none;
      }
    }
  }
  &.is-valid {
    .picker-input__text,
    .range-picker-input {
      border-color: $green;
      &:focus {
        box-shadow: none;
      }
    }
  }

  .range-picker-input__start,
  .range-picker-input__end {
    width: 100%;
  }
}

.calendar {
  &__day {
    vertical-align: middle;
    padding: 0;
    border: 1px solid transparent;
    height: 36px;
    border-radius: 50%;
  }
  &__day--today {
    background: $secondary-white;
    &:not(.calendar__day--disabled) {
      background: rgba($color: #414241, $alpha: 0.5);
      border: 1px solid #414241;
    }
  }
  &__day--0:not(.calendar__day--disabled),
  &__day--6:not(.calendar__day--disabled) {
    color: $secondary-red;
  }
  &__day:hover:not(.calendar__day--disabled) {
    background: rgba($color: #414241, $alpha: 0.9);
  }

  &__day--range {
    background: rgba($color: #414241, $alpha: 0.3);
  }

  &__head--title {
    cursor: pointer;
  }

  &__year,
  &__month {
    &:hover {
      background: rgba($color: #414241, $alpha: 0.6);
    }
  }
  &__day--today:not(.calendar__day--disabled) {
    border: 1px solid transparent;
  }
}

.calendar__head {
  background: $white !important;
}
.calendar__head--title {
  color: $black;
}
.calendar__head--button svg {
  fill: $black;
}

.calendar__day--start,
.calendar__day--end,
.calendar__day--selected {
  background: #414241 !important;
  color: $primary-dark-color;
  border-radius: 50%;
  color: #fff;
}

.calendar__panel--today {
  background: #414241;
  color: #fff;
}
