@import "../../assets/styles/imports/global";

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $primary-color;
  z-index: 99;
  .text-wrap {
    padding: 12px 0;
  }
  .subtitle2 {
    color: $white;
    text-align: center;
  }
}
