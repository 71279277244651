.edit-member-details-outer-wrap {
  margin: 36px 0 125px;
  .edit-member-details-wrap {
    padding: 22px 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(7, 7, 7, 0.1);
    border: 1px solid gray;
  }

  .edit-member-header {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding-bottom: 20px;

    .editHeading {
      display: flex;
      gap: 7px;
      align-items: center;

      h1 {
        font-weight: bold;
        font-size: 17px;
      }

      .editHeadingIcon {
        cursor: pointer;
        border: 1px solid gray;
        border-radius: 5px;
        padding: 3px;
        font-size: 25px;

        &:hover {
          background-color: #414241;
          color: white;
        }
      }
    }
  }

  .edit-member-title {
    text-align: left;
    h1 {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .editMemberInfo {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;

    .member-avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #414241;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 24px;
      margin-right: 15px;
    }

    .member-info {
      text-align: left;

      .memberEm {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-top: 10px;
      }

      p {
        &:first-child {
          font-weight: bold;
          font-size: 18px;
          color: black;
        }
      }
      .member-name,
      .member-email {
        font-size: 18px;
        color: gray;
      }
    }
  }

  .edit-member-info {
    .customEditDropdown {
      margin-top: 40px;
      display: flex;
      gap: 25px;
      align-items: center;
      width: 49%;
    }

    .customSiteRoleDrop {
      display: flex;
      gap: 25px;
      align-items: center;
      width: 49%;
      flex-direction: row;
    }

    .trainingYear-input {
      margin-top: 30px;

      .trainingYear-inputWrapper {
        display: flex;
        flex-direction: column;
        height: 40px;

        .error {
          color: red;
          display: flex;
          align-items: center; /* Center the icon with the text */
          font-size: 12px;
          font-weight: normal;
          margin-top: 4px;
          text-decoration: none;
          gap: 4px;

          p {
            margin-left: 10px; /* Space between icon and text */
          }
        }
        .trainingYear-inputWrapper-label {
          margin: 8px 0px 0px 6px;
        }
      }

      input {
        width: 287px;
        padding: 7px;
        border-radius: 5px;
        border: 1px solid grey;
      }

      p {
        font-size: 16px;
        color: #666;
        font-weight: normal;
      }
    }

    h2 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: left;
    }
    .dropdownWrapper {
      select {
        border: 1px solid gray;
        border-radius: 5px;
        padding: 6px;
        color: #666;
        width: 100%;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 130px;
    .cancel-button {
      background-color: #fff;
      color: #414241;
      border: 1px solid #414241;
      padding: 10px 20px;
      border-radius: 5px;
    }
    .save-changes-button {
      background-color: #414241;
      border: none;
      padding: 10px 20px;
      color: white;
      text-align: center;
      border-radius: 5px;
    }
  }
}
