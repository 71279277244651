.card {
    position: relative;
    background-color: $white;
    border: 1px solid rgba($color: $black, $alpha: 0.125);
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba($color: $black, $alpha: 0.16), 0 2px 10px 0 rgba($color: $black, $alpha: 0.12);
    &.no-shadow {
        box-shadow: none !important;
    }
    &.no-border {
        border: 0;
    }
    &.card-with-border {
        border: 1px solid rgba($color: $secondary-blue, $alpha: 0.25);
        box-shadow: 1px 1px 15px rgba($color: $black, $alpha: 0.15);
    }
    .card-body {
        width: 100%;
    }
}