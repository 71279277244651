.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;

    .popup-close {
      display: flex;
      justify-content: flex-end;
      font-size: 20px;
      cursor: pointer;
    }

    h2 {
      font-size: 20px;
      font-weight: bold;
    }

    p {
      margin: 20px 0;
      font-size: 13px;
      line-height: 20px;
    }

    .popup-details {
      margin-top: 15px;
      margin-bottom: 30px;

      .popup-info-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;

        .popup-info-item {
          background-color: #f4f4f4;
          border-radius: 6px;
          padding: 10px;
          flex: 1 1 calc(48%);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          transition: transform 0.2s ease, box-shadow 0.2s ease;

          &:hover {
            transform: translateY(-3px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }

          strong {
            font-size: 14px;
            color: #414241;
            display: block;
            margin-bottom: 3px;
            text-transform: uppercase;
            letter-spacing: 0.8px;
          }

          .popup-info-value {
            color: #5f5e5e;
            font-size: 12px;
          }

          p {
            margin: 0;
            font-size: 12px;
            color: #555;
          }

          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: 12px;
            color: #666;
            display: inline;
          }

          li {
            display: inline;
            margin-right: 5px;
          }
        }
      }
    }

    .popup-buttons {
      display: flex;
      align-items: center;
      gap: 15px;
      justify-content: center;
      width: 100%;

      button {
        padding: 10px 25px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        font-size: 14px;

        &:first-of-type {
          background-color: #fff;
          color: #414241;
          border: 1px solid #414241;
        }

        &:last-of-type {
          background-color: #414241;
          color: #fff;
        }
        &:disabled {
          cursor: not-allowed;

          &:last-of-type {
            background-color: #cccccc;
            color: #666666;
          }
        }
      }
    }
  }
}
