@import '../../assets/styles/imports/global';

.breadcrumb-wrap {
    padding: 4px 0 20px 0;
    .breadcrumb {
        margin-bottom: 0;
    }
    .breadcrumb-item {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: $font-weight-600;
        color: $primary-dark-color;
        + .breadcrumb-item::before {
            content: "\E90C";
            font-family: icomoon;
            font-size: 14px;
            color: rgba($color: $tertiary-black, $alpha: 0.4);
        }
        &.active {
            color: rgba($color: $tertiary-black, $alpha: 0.4);
        }
        [class^="icon"] {
            vertical-align: bottom;
        }
        a {
            font-size: 16px;
            font-weight: $font-weight-600;
            color: $primary-dark-color;
            text-decoration: none;
            position: relative;
            &:after {
                content: '';
                width: 100%;
                height: 1px;
                left: 0;
                bottom: 0;
                position: absolute;
            }
            &:hover {
                color: $primary-dark-color;
                &:after {
                    background: $primary-dark-color;
                }
            }
        }
    }
}