.options-card {
    display: flex;
    flex-direction: column;
    width: 150px;
  
    button {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      background: none;
      border: none;
      cursor: pointer;
      text-align: left;
      width: 100%;
      
      svg {
        margin-right: 10px;
      }
  
      &:hover {
        background-color: #f8f9fa;
      }
    }

    .delete {
      color: red;
    }
  }
  