@import '../../assets/styles/imports/global';

.paginator-wrap {
    .page-item {
        &:not(:first-child) {
            .page-link {
                margin-left: 8px;
            }
        }
        &.page-prev {
            span {
                font-size: 0px;
                width: 10px;
                display: inline-block;
                &:after {
                    display: block;
                    content: "\E90B";
                    font-family: icomoon;
                    @include transformXY;
                    font-size: 16px;
                    color: $primary-dark-color;
                }
            }
        }
        &.page-next {
            span {
                font-size: 0px;
                width: 10px;
                display: inline-block;
                &:after {
                    display: block;
                    content: "\E90C";
                    font-family: icomoon;
                    @include transformXY;
                    font-size: 16px;
                    color: $primary-dark-color;
                }
            }
        }
        .page-link {
            border-radius: 4px;
            font-size: 14px;
            padding: 8px;
            background: $white;
            box-shadow: none !important;
            color: $primary-dark-color;
            z-index: 0;
        }
        &.active {
            .page-link {
                background: rgba($color: $primary-color, $alpha: 0.9);
                border: 1px solid rgba($color: $black, $alpha: 0.5);
                color: $primary-dark-color;
            }
        }
    }
}