.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.reset-password-container {
  display: flex;
  flex-direction: column;
  // align-items: center;
  padding: 20px;
  //   height: 90vh;
  .reset-password-container-breadcrumb {
    margin: 20px 0px 0px 30px;
  }
}

.reset-password-nav {
  width: 100%;
  text-align: left;
  padding: 10px 0;
}

.reset-password-logo img {
  width: 150px;
}

.reset-password-content {
  width: 100%;
  //   max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.reset-password-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 420px;
  width: 340px;
  min-height: 360px;
}

.reset-password-card-body {
  padding: 20px;

  .reset-password-disclosure {
    text-align: center;
    color: #6c757d;
    font-size: 12px;
  }
}

.reset-password-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.rest-form-wrap {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  //   align-items: center;
  gap: 20px;
  justify-content: center;
}

.reset-password-form .form-group {
  margin-bottom: 40px;
}

.password-input-wrap {
  position: relative;
}

.password-input-wrap input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 35%;
  transform: translateY(-50%);
  cursor: pointer;
}

.input-error {
  border-color: red;
}

.password-instruction {
  font-size: 12px;
  color: #6c757d;
  margin-top: 0.5rem;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.error-message {
  color: red;
  text-align: center;
}

.success-message {
  color: green;
  text-align: center;
}

@media (max-width: 768px) {
  .reset-password-container {
    padding: 10px;
  }

  .reset-password-card {
    padding: 15px;
  }

  .reset-password-title {
    font-size: 20px;
  }

  .reset-password-logo img {
    width: 120px;
  }
}

@media (max-width: 767px) {
  .reset-password-container {
    .breadcrumb-wrap {
      display: none;
    }
    .reset-password-content {
      .reset-password-card {
        .reset-password-card-body {
          .reset-password-disclosure {
            display: none;
          }
        }
      }
    }
  }
}

// //////////////////////////////////////////////ResetSuccess.js/////////////////////////

.success-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
  text-align: center;
  gap: 10px;

  h1 {
    font-size: 25px;
    font-weight: bold;
  }
}
