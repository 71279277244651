@import '../../../assets/styles/imports/global';

.login-screen-wrap {
    // height: 100vh;
}

.login-form-wrap {
    .login-form-group {
        margin-bottom: 30px;

        .form-control {
            position: relative;
            padding-right: 2.25rem; /* Space for the icon */
        }

        .form-control.is-invalid {
            border-color: #dc3545;
            padding-right: 2.25rem; /* Space for the icon */
        }

        .form-control.is-invalid + .invalid-feedback {
            display: block;
            font-size: 0.875em;
            color: #dc3545;
        }

        .form-control + .invalid-feedback {
            display: none;
        }
    }

    .form-check {
        margin-bottom: 8px;
    }
}

.login-card-wrap {
    @include transformXY;
    top: 45%;
    .card {
        width: 420px;
        height: 420px;
        padding: 30px 0;
        @include flex(flex, center, center, nowrap);
        position: relative;
        overflow: hidden;
        .card-body {
            padding: 0;
        }
        .card-title {
            margin-bottom: 24px;
        }
        .subtitle1 {
            color: $primary-dark-color;
            font-family: $font-med;
            text-align: center;
        }
        .subtitle2 {
            color: $secondary-grey;
            font-family: $font-med;
            text-align: center;
            width: 65%;
            margin: 0 auto;
            padding: 24px 0 60px 0;
        }
    }
    .login-card-body {
        width: 80%;
        visibility: hidden;
        opacity: 0;
        @include transformXY;
        transition: 0.5s ease;
    }
    .login-action-body {
        left: -50%;
        &.show {
            opacity: 1;
            left: 50%;
            visibility: visible;
        }
    }
    
    .forgot-action-body {
        left: 150%;
        &.show {
            opacity: 1;
            left: 50%;
            visibility: visible;
        }
    }
}
