@import '../../assets/styles/imports/global';

.feature-card-wrap {
    text-align: center;
    cursor: pointer;
    margin-bottom: 24px;
    width: 100%;
    &:hover {
        .feature-icon-wrap {
            transform: scale(1.1);
        }
        .feature-title {
            h6 {
                color: $primary-dark-color;
            }
        }
    }
    .card-body {
        padding: 0 44px;
    }
    .feature-icon-wrap {
        @include flex(flex, center, center, nowrap);
        height: 172px;
        font-size: 80px;
        color: $primary-green;
        transition: 0.5s ease;
        img {
            width: 54px;
        }
    }
    .feature-title {
        border-top: 1px solid $grey; 
        min-height: 93px;
        @include flex(flex, center, center, nowrap);
        h6 {
            transition: 0.5s ease;
            text-transform: uppercase;
            color: $primary-dark-color;
            font-family: $font-bold;
        }
    }
}

.feature-content-row {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba($color: $secondary-blue, $alpha: 0.15);
    width: 100%;
    &.no-border {
        border-bottom: 0;
    }
}

.feature-content-card-wrap {
    width: 100%;
    .feature-content-title-wrap {
        margin-bottom: 24px;
        .text-wrap {
            .subtitle1 {
                font-weight: $font-weight-600;
                margin-bottom: 32px;
                color: $primary-dark-color;
            }
            .subtitle2 {
                margin-bottom: 8px;
                color: $secondary-blue;
            }
        }
    }
    .feature-content-details-wrap {
        .text-wrap {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
            strong {
                color: $primary-dark-color;
            }
            > * {
                color: $secondary-blue;
            }
            /* .title {
                // font-weight: $font-weight-600;
                color: $secondary-blue;
                padding-right: 8px;
            }
            .subtitle2 {
                color: $secondary-blue;
            } */
        }
    }
    .btn-wrap {
        .btn {
            &.btn-secondary {
                &:hover {
                    background: rgba($color: $tertiary-black, $alpha: 0.15);
                }
            }
        }
    }
}


.feature-program-card-wrap {
    cursor: pointer;
    .card {
        box-shadow: 1px 1px rgba($color: $black, $alpha: 0.15);
    }
    .feature-content-title-wrap {
        padding: 24px 0;
    }
    .feature-program-icon-wrap {
        font-size: 50px;
        color: $primary-green;
        height: 100%;
        @include flex(flex, center, center, nowrap);
        border-right: 1px solid $tertiary-grey;
        padding-right: 12px;
    }
    &.feature-program-count {
        position: relative;
        padding-left: 32px;
        &:after {
            content: "";
            @include transformY;
            height: 100%;
            width: 1px;
            left: 9px;
            background: rgba($color: $secondary-grey, $alpha: 0.3);
            z-index: 0;
        }
        .program-count {
            position: absolute;
            left: -36px;
            top: 12px;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $tertiary-white;
            color: $secondary-black;
            z-index: 1;
            font-size: 16px;
        }
    }
}