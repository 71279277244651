@import '../../assets/styles/imports/global';

// editor start
.editor-wrap {
  .jodit-container {
    border-radius: 0;
    border-color: $tertiary-grey;
    color: $secondary-black;
    font-size: 16px;

    .jodit-toolbar__box {
      border-color: $tertiary-grey;
      background-color: $secondary-white;
    }

    .jodit-toolbar-button__button {
      border-radius: 0;
    }

    .jodit-toolbar-button__button[aria-pressed=true]:not([disabled]) {
      background-color: rgba($color: $tertiary-black, $alpha: 0.2);
    }
  }
}

.form-readonly {
  .jodit-container {
    background-color: $secondary-white;
    cursor: not-allowed;
  }
}

.form-invalid {
  .jodit-wysiwyg {
    border: 1px solid $red;
  }
}

// editor end