.assign-survey-outer-wrap {
  margin: 36px 0 125px;

  .assign-survey-wrap {
    padding: 22px 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(7, 7, 7, 0.1);
    border: 1px solid gray;
  }

  .survey-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;

    .survey-header-main {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    h1 {
      font-weight: bold;
      font-size: 17px;
    }

    em {
      font-style: italic;
      font-weight: 400;
      color: gray;
    }

    .back-icon {
      cursor: pointer;
      border: 1px solid gray;
      border-radius: 5px;
      padding: 3px;
      font-size: 25px;

      &:hover {
        background-color: #414241;
        color: white;
      }
    }

    .question-count {
      span {
        font-weight: 400;
        font-size: 15px;
        font-style: italic;
        color: gray;
      }
    }
  }

  .divider {
    border: 0;
    border-top: 1px solid gray;
    margin-top: 15px;
  }

  .filter-panel {
    padding-right: 15px;

    .filter-header {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin-top: 10px;

      .filter-header-filterMain {
        display: flex;
      }

      .filter-header-clearTex {
        color: #2256da;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        text-decoration-line: underline;
        cursor: pointer;
      }

      h5 {
        font-weight: bold;
      }
    }

    .filter-section {
      .filter-title {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-top: 1px solid lightgray;
      }

      .filter-options {
        padding: 10px;
      }
    }
  }

  .assign-survey-content {
    padding-left: 15px;
    margin: 0;
    position: relative;

    .assignSurvey {
      display: flex;
      align-items: center;
      gap: 15px;
    }

    h3 {
      font-weight: bold;
    }

    .search-bar {
      display: flex;
      align-items: center;
      position: relative;

      .dropdown-card {
        position: absolute;
        z-index: 1000;
        background-color: white;
        border: 1px solid #ddd;
        border-radius: 10px;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
        width: 500px;
        max-height: 200px;
        overflow-y: auto;
        top: calc(100% + 5px); /* Adjust this value as needed */
        left: 0;

        .selected-members-header {
          padding: 10px;
          font-weight: normal;
          font-size: 14px;
          color: gray;
        }

        .members-list {
          padding: 10px;
        }

        .member-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          border-bottom: 1px solid #f0f0f0;

          .member-action-added {
            font-size: 14px;
            font-weight: normal;
            display: flex;
            align-items: center;
            gap: 5px;
            color: gray;
            cursor: pointer;
          }

          .member-action-add {
            font-size: 17px;
            font-weight: normal;
            display: flex;
            align-items: center;
            gap: 5px;
            color: blue;
            cursor: pointer;
          }

          &.disabled {
            background-color: #f9f9f9;
            pointer-events: none;
          }

          .member-initials {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: #ddd;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
          }

          .member-details {
            flex-grow: 1;
            padding-left: 10px;

            .member-name {
              font-weight: bold;
            }

            .member-email {
              color: gray;
              font-size: 12px;
              margin-top: 10px;
            }
          }

          .member-action {
            cursor: pointer;
            font-weight: bold;

            &.add {
              color: blue;
            }

            &.added {
              color: gray;
            }
          }
        }
      }

      .search-icon {
        position: absolute;
        left: 10px;
      }

      .close-icon {
        position: absolute;
        right: 10px;
        cursor: pointer;
      }

      .form-control {
        padding: 10px 30px;
        border-radius: 10px;
        border: 1px solid #ddd;
        width: 500px;
      }
    }

    .selected-members {
      .selected-members-header {
        font-weight: bold;
        margin-top: 20px;
      }

      .selected-members-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 15px;

        .selected-member {
          display: flex;
          align-items: center;
          gap: 5px;
          background-color: #eaedef;
          padding: 10px 15px;
          border-radius: 30px;

          .member-initials {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #ddd;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
          }

          .member-name {
            font-weight: normal;
          }

          .remove-icon {
            cursor: pointer;
          }
        }
      }
    }
  }

  .footer-survey-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    padding: 15px 30px;
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.05);

    .btnPreviousSurvey {
      color: #414241;
      background-color: white;
      border: 1px solid lightgray;
      border-radius: 5px;
      padding: 10px 70px;
      text-transform: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      &:hover {
        background-color: lightgray;
        border: none;
      }
    }

    .right-buttons {
      display: flex;
      align-items: center;
      gap: 10px;

      .btn-outline-primary {
        color: #007bff;
        border-color: #007bff;
        padding: 10px 20px;
      }

      .btn-primary {
        background-color: #414241;
        border-radius: 5px;
        border: none;
        outline: none;
        padding: 10px 20px;
        color: white;
        text-align: center;
        text-transform: none;

        &:hover {
          background-color: #333;
        }
      }
    }
  }
}
