@mixin pageFont($color, $fontsize, $fontweight) {
  color: $color;
  /* Body/14/14-Regular */
  font-family: Lato;
  font-size: $fontsize;
  font-style: normal;
  font-weight: $fontweight;
}

.survey-details {
  .survey-details-asignedSurvey {
    margin-top: 20px;
  }

  .survey-details-subHeading {
    @include pageFont(#414241, 18px, 700);
    margin-bottom: 20px;
  }

  .label {
    @include pageFont(#666, 14px, 400);
    margin-bottom: 8px;
  }

  .value {
    @include pageFont(#414241, 16px, 600);
  }
  .member-value {
    @include pageFont(blue, 16px, 600);
    text-decoration: underline;
    text-underline-offset: 3px;
    cursor: pointer;
  }
}

.modal-body {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.modal-header {
  background-color: #414241;
  color: #eaedef;
}
.btn-close {
  color: #eaedef !important;
}
.selected-member {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #eaedef;
  padding: 8px 8px;
  border-radius: 30px;

  .member-initials {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .member-name {
    font-weight: normal;
  }
}
