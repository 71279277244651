@import '../../../assets/styles/mixins.module.scss';

.verifyModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .verifyModalContent {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    width: 400px;
    text-align: center;
    position: relative;

    .modalClose {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    .verifyHead {
      h2 {
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: bold;
      }

      span{
        font-weight: bold;
      }

      p {
        font-size: 14px;
        color: #666;
        margin-bottom: 6px;
      }
    }

    .verifyBoxes {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      input {
        width: 40px;
        height: 40px;
        font-size: 24px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        transition: border-color 0.3s;

        &:focus {
          border-color: #007bff;
        }

        &.inputError {
          border-color: red;
        }
      }
    }

    .error {
      color: red;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: normal;
      margin-top: 10px;
      text-decoration: none;
      gap: 4px;
    }

    .verifyFooter {
        margin-top: 20px;
      p {
        font-size: 12px;
        color: #666;
        text-align: left;

        span {
          color: #007bff;
          cursor: pointer;
        }
      }

      button {
        width: 100%;
        margin-top: 15px;
        background-color: #414241;
        border-radius: 5px;
        border: none;
        outline: none;
        padding: 10px;
        color: white;
        text-align: center;
      }
    }
  }
}

  