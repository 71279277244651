.AppDownload{
    margin-bottom: 50px;
    .downloadTitle{
        text-align: center;
    }
    .appIcons{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 10px;

        img{
            object-fit: contain;
            height: auto;
            width: 30px;
            cursor: pointer;
        }
    }
}