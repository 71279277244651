.search-box-wrapper {
    position: relative;
    width: 300px;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 10px;
  
    .search-icon {
      font-size: 20px;
    }
  
    .search-box {
      padding: 10px;
      border: none;
      outline: none;
    }
  }
  